import { mapGetters } from "vuex"

const traineeNavigationMixin = {
    data: () => {
        return {
            traineeHomeRoute: { name: "index" },
        }
    },
    created() {
        this.traineeHomeRoute = this.calculateHomeRoute()
    },
    watch: {
        async $route() {
            this.traineeHomeRoute = this.calculateHomeRoute()
        },
    },
    methods: {
        routeToTraineeSearch() {
            const trainee = this.$auth.user?.trainee
            if (trainee?.primary_address) {
                if (
                    trainee.primary_address.lat &&
                    trainee.primary_address.lng
                ) {
                    return {
                        name: "ausbildung-locationSlug",
                        params: {
                            locationSlug: "nearby",
                        },
                        query: {
                            latitude: trainee.primary_address.lat,
                            longitude: trainee.primary_address.lng,
                        },
                    }
                }
            }
            return {
                name: "index",
            }
        },
        calculateHomeRoute() {
            if (this.$route.name?.includes("schwarzes-brett")) {
                if (this.previousSearchRoute) {
                    return this.previousSearchRoute
                }
                this.routeToTraineeSearch()
            }
            if (this.isSearchActive) {
                let currentRoute = { ...this.route({}) }
                currentRoute.query = undefined
                return currentRoute
            }
            return this.routeToTraineeSearch()
        },
    },
    computed: {
        ...mapGetters("search", {
            isSearchActive: "active",
            route: "route",
        }),
    },
}

export default traineeNavigationMixin
