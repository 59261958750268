
import { mapGetters } from "vuex"
import { mdiBookmarkCheckOutline, mdiBookmarkCheck } from "@mdi/js"
import { speechbubble_message, speechbubble_filled } from "@/assets/icons/js"
import { routeToChat, routeToTraineeFavorites } from "@/helpers/routing"
import traineeNavigationMixin from "@/mixins/traineeNavigationMixin"

import LoggedInUserAvatar from "@/components/user/LoggedInUserAvatar"
import Button from "@/components/base/Button"

export default {
    components: { Button, LoggedInUserAvatar },
    mixins: [traineeNavigationMixin],
    data: () => ({
        routeToChat,
        routeToTraineeFavorites,
    }),
    computed: {
        ...mapGetters("chat", ["unreadMessages"]),
        ...mapGetters("rating", ["newRatingsCount"]),
        unreadMessages() {
            return this.$store.getters["chat/getTotalUnreadMessages"]
        },
    },
    icons: {
        mdiBookmarkCheckOutline,
        mdiBookmarkCheck,
        speechbubble_message,
        speechbubble_filled,
    },
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        openDialog() {
            this.$store.dispatch("account/prepareOnboarding", true)
            this.$store.dispatch("account/showAuth")
        },
        isActive(route) {
            return this.$route.name.includes(route.name)
        },
    },
}
