
import Button from "@/components/base/Button"
import LoginProfileButtons from "@/components/auth/LoginProfileButtons"
import HeadlineLogo from "@/components/region/passau/HeadlineLogo"

export default {
    name: "RegionHeader",
    components: { HeadlineLogo, LoginProfileButtons, Button },
    props: {
        showCarousel: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        images: [
            require("@/assets/images/categories/v2/art.jpg"),
            require("@/assets/images/categories/v2/chemistry.jpg"),
            require("@/assets/images/categories/v2/craft.jpg"),
            require("@/assets/images/categories/v2/environment.jpg"),
            require("@/assets/images/categories/v2/it.jpg"),
            require("@/assets/images/categories/v2/machine.jpg"),
        ],
    }),
    computed: {
        isDarkPage() {
            return (
                this.$route.name === "region-freyung-grafenau" ||
                this.$route.name === "region-freyung-grafenau-anmeldung"
            )
        },
    },
}
