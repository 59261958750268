
export default {
    name: "HeadlineLogo",
    props: {
        primary: {
            type: String,
            default: "#1d272f",
        },
        secondary: {
            type: String,
            default: "white",
        },
        uppercase: {
            type: Boolean,
            default: true,
        },
        center: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        mainStyle() {
            return {
                background: this.primary,
                color: this.secondary,
            }
        },
        subtitleStyle() {
            return {
                background: this.secondary,
                color: this.primary,
            }
        },
    },
}
