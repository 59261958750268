
import { mdiAccountCircle } from "@mdi/js"
import { routeToProfile } from "@/helpers/routing"

export default {
    name: "LoggedInUserAvatar",
    props: {
        size: {
            type: Number,
            default: 48,
        },
        color: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            mdiAccountCircle,
        }
    },
    computed: {
        profileImage() {
            return this.$getImage({
                image: this.$auth.user.profile_picture,
                preferedSize: "3",
            })
        },
        userRouting() {
            return routeToProfile(this.$auth.user)
        },
    },
}
